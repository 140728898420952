<template lang="pug">
    div.page
        div.box-primary.mtd-row
            div.lg-030-t
                div.img-wrapper
                    img.img-logo(src="./../assets/img/logo-medclub.png")
            div.lg-070-t.b-side
                h1.text-title.ta-center PAINEL DE ALTERAÇÃO DE SENHA
                div(v-if='waiting')
                    div.loader(style='margin: 80px auto;') <div></div><div></div><div></div>
                div(v-else-if='! token' style='margin: 40px 0;')
                    h2.text-description.error ERRO: <b>token</b> não encontrado. Verifique o endereço eletrônico digitado.<br/><br/>
                div(v-else-if='complete')
                    h2.text-description.success.ta-center {{ complete }}<br/><br/>
                div(v-else)
                    h2.text-description Por favor, complete a alteração de senha preenchendo os dados abaixo.
                    form.form-input(@submit="onSubmit")
                        div.input-field
                            label.label Nova senha:
                            input.input(type="password" v-model='senha' @input='clearValidation()')
                        div.input-field
                            label.label Repetir senha:
                            input.input(type="password" v-model='senhaRep' @input='clearValidation()')
                        div.box-errors(v-if='! validation.isValid' style='margin: 20px 0')
                            p.error(v-for='message in validation.messages') {{ message }}
                        div.input-field.ta-right(v-if='! formSubmitted')
                            button.btn-confirm(type="submit") Salvar
                        div(style='float:right; margin: 20px 0' v-if='formSubmitted && validation.isValid')
                            div.loader <div></div><div></div><div></div>
</template>

<script>
    import axios from 'axios'
    export default {
        data () {
            return {
                wsBaseUrl: 'https://api.medclub.com.br/v1',
                senha: '',
                senhaRep: '',
                validation: { isValid: true, messages: [] },
                token: null,
                complete: undefined,
                formSubmitted: false,
                waiting: false
            }
        },
        created () {
            if (this.$route.query.token) {
                this.token = this.$route.query.token
                this.waiting = true
                axios.get(`${ this.wsBaseUrl }/global/validar-token/?ds_token=${ this.token }`).then(() => {
                    // token is valid
                }).catch(() => {
                    this.token = null
                }).finally(() => {
                    this.waiting = false
                })
            }
        },
        methods: {
            onSubmit (ev) {
                ev.preventDefault()
                if (this.validate()) {
                    let vm = this
                    vm.formSubmitted = true
                    axios.post(`${ vm.wsBaseUrl }/global/esqueci-minha-senha/`, {
                        ds_password1: vm.senha, ds_password2: vm.senhaRep, ds_token: vm.token
                    }).then(response => {
                        if (response.data)
                            if (response.data.detail) vm.complete = response.data.detail
                    }).catch(err => {
                        if (err.response.data)
                            vm.addError(err.response.data.detail)
                    })
                }
            },
            addError (message) {
                this.validation.isValid = false
                this.validation.messages.push(message)
            },
            validate () {
                this.clearValidation()

                // Senha maior que 6 caracteres e menor que 100
                if (this.senha.length < 6 || this.senha.length > 100)
                    this.addError('Senha deve ter entre 6 e 100 caracteres.')

                // Senha e repetição iguais
                if (this.senha != this.senhaRep)
                    this.addError('As senhas digitadas devem ser iguais.')

                return this.validation.isValid
            },
            clearValidation () {
                if (! this.formSubmitted)
                    this.validation = { isValid: true, messages: [] }
            }
        }
    }
</script>